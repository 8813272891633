
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { Detail } from '@/types/parkStrategy'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'ParkStrategyAdd',
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private formData: Detail = {
    projectId: '',
    strategyTheme: '',
    strategyContent: '',
    resourceList: []
  }

  private submitDisabled = false

  private rulesForm = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    strategyTheme: [
      { required: true, message: '请输入攻略标题', trigger: 'blur' }
    ],
    strategyContent: [
      { required: true, message: '请选输入攻略内容', trigger: 'blur' }
    ],
    resourceList: [
      { required: true, message: '请上传图片', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  confirmAdd () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitDisabled = true
        this.$axios.post(this.$apis.parkStrategy.insertParkStrategy, this.formData).then((res: any) => {
          this.$message.success('新增成功')
          this.$router.push({ path: '/parkStrategy' })
        }).finally(() => {
          this.submitDisabled = false
        })
      }
    })
  }

  imgRemove (index: number) {
    this.formData.resourceList.splice(index, 1)
  }

  uploadSuccess (file: FileInfo) {
    this.formData.resourceList.push(file)
  }
}
